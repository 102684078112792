/* eslint-disable */
// request urls
import { apiGetAllApplications } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'

// actions
import { getApplications } from '../../reduxstore/actions/application/getApplications'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (dispatch, setLoading) => {
  const request = await get(apiGetAllApplications())

  const { data, error } = request

  // handle success
  data && dispatch(getApplications(data.data))

  // set loading state
  setLoading(false)

  // handle failure
  error && dispatch(setAlert('error', error))
}
