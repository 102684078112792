import React from 'react';
import logo from "../../../assets/images/fox2_512.png"


function Logo() {
  return <img style={{
    flex: 1,
    mixBlendModde: "difference",
    color: "transparent",
    // filter: "drop-shadow(6px 2px 10px black)",
    height: 50,
    justifyContent: "left"
  }} src={logo} alt="Logo" />;
}

export default Logo;
