import React from 'react'
import ReactPlayer from 'react-player'
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
// import GridContainer from "../customComponents/Grid/GridContainer";
// import GridItem from "../customComponents/Grid/GridItem.js";

import styles from "../../assets/youtubeStyle";

const useStyles = makeStyles(styles);

export default function VideoPlayer() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <div className={classes.container}>
        {/* <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}> */}
            <ReactPlayer
                className='react-player fixed-bottom'
                url= 'https://vixalopexvideo.s3.amazonaws.com/app-developer-video.mp4'
                width='100%'
                height='100%'
                controls = {true}
                playing={true}
                muted={true}
                />
          {/* </GridItem>
        </GridContainer> */}
      </div>
    </div>
  );
}