/* eslint-disable */
// request urls
import { apiGetAllSubscriptionLevelsByApplication } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'

// actions
import { getSubscriptionLevels } from '../../reduxstore/actions/subscriptionLevel/getSubscriptionLevels'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (dispatch, setLoading, application_id) => {
  const request = await get(apiGetAllSubscriptionLevelsByApplication(application_id))

  const { data, error } = request

  // handle success
  data && dispatch(getSubscriptionLevels(data.data))

  // set loading state
  setLoading(false)

  // handle failure
  error && dispatch(setAlert('error', error))
}
