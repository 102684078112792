import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "../../customComponents/Header/Header.js";
import Footer from "../../customComponents/Footer/Footer.js";
import HeaderLinks from "../../customComponents/Header/HeaderLinks.js";
import Logo from "../../customComponents/Logo/Logo.js";
import LogoName from "../../customComponents/Logo/LogoName.js";

import styles from "../../../assets/mainStyle.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function BaseViewRoute(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        // color="transparent"
        routes={dashboardRoutes}
        brand={<div><LogoName /><Logo /></div>}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <div className={classes.main}>
        {props.component}
      </div>
      <Footer />
    </div>
  );
}
