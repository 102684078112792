/* eslint-disable */
// user action constants
import {
    APPLICATIONS_SUCCESS
  } from "../actions/types";
  
  // default subscritions state
  const defaultState = {
    application: {
        id: null,
        application_name: null,
        url: null,
        status: null
    },
    applications: [],
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case APPLICATIONS_SUCCESS:
        return {
          ...state,
          applications: action.payload
        }

      default:
        return state;
    }
  };
  