import { createStore, combineReducers } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import user from "../reducers/user";
import ui from "../reducers/ui";
import subscriptions from "../reducers/subscriptions";
import subscriptionLevels from "../reducers/subscriptionLevels";
import stripe from "../reducers/stripe";
import email from "../reducers/email";
import applications from "../reducers/applications"

// Combine all reducers.
import { DESTROY_AVAILABLE_SESSION } from "../actions/types";

const appReducer = combineReducers({
  user,
  ui,
  subscriptions,
  subscriptionLevels,
  stripe,
  email,
  applications
});
const rootReducer = (state, action) => {   
  // Clear all data in redux store to initial.
  if(action.type === DESTROY_AVAILABLE_SESSION)
     state = undefined;
  return appReducer(state, action);
};


export default createStore(rootReducer, devToolsEnhancer());
