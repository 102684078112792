/* eslint-disable */
// request urls
import { apiGetSubscription } from '../../api/urls'

// utilities
import get from '../../utilities/fetch/get'

// actions
import { getSubscription } from '../../reduxstore/actions/subscription/getSubscription'
import { setAlert } from '../../reduxstore/actions/ui/setAlert'

export default async (dispatch, setLoading, subscriptionId) => {
  const request = await get(apiGetSubscription(subscriptionId))

  const { data, error } = request

  // handle success
  data && dispatch(getSubscription(data.data[0]))

  // set loading state
  setLoading(false)

  // handle failure
  error && dispatch(setAlert('error', error))
}
