// api config
import { API_URL } from '../api/config'

// register and login
export const apiRegister = () => `${API_URL}/register`
export const apiUserAuth = () => `${API_URL}/login`

// user
export const apiGetUser = (userId) => `${API_URL}/user/${userId}`
export const apiGetAllUsers = () => `${API_URL}/users`
export const apiUpdateUser = (userId) => `${API_URL}/user/${userId}`
export const apiDeleteUser = (userId) => `${API_URL}/user/${userId}`
export const apiUpdateUserPassword = () => `${API_URL}/user/password`

// applications
export const apiGetAllApplications = () => `${API_URL}/applications`

// subscription levels
export const apiGetSubscriptionLevel = (subscriptionLevelId) => `${API_URL}/subscriptionLevels/${subscriptionLevelId}`
export const apiGetAllSubscriptionLevelsByApplication = (application_id) => `${API_URL}/subscriptionLevels?application_id=${application_id}`

// subscription
export const apiGetSubscription = (subscriptionId) => `${API_URL}/subscriptions/${subscriptionId}`
export const apiUpdateSubscription = (subscriptionId) => `${API_URL}/subscriptions/${subscriptionId}`
export const apiDeleteSubscription = (subscriptionId) => `${API_URL}/subscriptions/${subscriptionId}`
export const apiGetAllSubscriptions = (stripeId) => `${API_URL}/subscriptions?stripe_id=${stripeId}`
export const apiCreateSubscription = () => `${API_URL}/subscriptions`

// vixalopex mailer
// need to replace this with new lambda function for vixalopex site
export const apiConfirmationEmail = () => `https://fa2zs8n4nl.execute-api.us-east-1.amazonaws.com/dev/vixalopexappmailer`

// stripe
export const apiGetStripeSecretKey = (price) => `${API_URL}/stripe?price=${price}`
export const apiGetStripePromiseKey = () => `${API_URL}/stripeP`
export const apiGetStripeCustomerSecretKey = () => `${API_URL}/stripeCustomerKey`
export const apiPostStripeCreateCustomer = () => `${API_URL}/stripeCreateCustomer`
export const apiPostStripeCreateSubscription = () => `${API_URL}/stripeCreateSubscription`
export const apiPostStripeUpdateSubscription = () => `${API_URL}/stripeUpdateSubscription`
export const apiPostStripeCancelSubscription = () => `${API_URL}/stripeCancelSubscription`

export const apiEmail = () => `https://ing0g4m48e.execute-api.us-east-1.amazonaws.com/vixalopexstageapi/vixalopexlambdaresource`