import React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import Header from "./customComponents/Header/Header.js";
import Footer from "./customComponents/Footer/Footer";
// import HeaderLinks from "./customComponents/Header/HeaderLinks.js";
import Parallax from "./customComponents/Parallax/Parallax.js";
// import bg from "../assets/images/output_1164211551_0.jpg";
import bg from "../assets/images/output_2195613349_1.jpg";
import Logo from "./customComponents/Logo/Logo.js";
import logoText from "../assets/images/VixalopexNoBgWhite.png"

import styles from "../assets/mainStyle.js";
import { Typography } from "@material-ui/core";
import Button from "./customComponents/CustomButtons/Button";
import VideoPlayer from "./Views/VideoPlayer.js";
import { setActiveComponent } from "../config/reduxstore/actions/ui/setActiveComponent"

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Main(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { ...rest } = props;

  const handleSetActiveComponent = (componentName) => {
    dispatch(
      setActiveComponent(`Views/${componentName}`)
    )
  };

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={<Logo />}
        // rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={bg}>
        <div className={classes.logoContainer}>
        <img style={{
          // flex: 1,
          mixBlendModde: "difference",
          color: "transparent",
          filter: "drop-shadow(6px 2px 10px black)",
          width: "70%",
          height: "10%",
          justifyContent: "center"
        }} src={logoText} alt="LogoName" />
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div style={{padding: 40}}>
          <Typography>
            At Vixalopex our mission is simple. It's to deliver the best possible reliable
            software solutions to help our clients improve efficiency and business
            profitability. This includes web development, mobile app development, 
            api creation and integration, amazon alexa skill creation, animation, etc...
          </Typography>
          <br />
            <Typography>To explore some of the work we have done, please click on the Explore tab above or click on the button below.</Typography>
            <Button onClick={() => handleSetActiveComponent(`CurrentCustomers`)}>
              Current Customers
            </Button>
            {" "}
            <Button onClick={() => handleSetActiveComponent(`IMS`)}>
              Software
            </Button>
        </div>
        <div className={classes.container}>
          <VideoPlayer />
        </div>
      </div>
      <Footer />
    </div>
  );
}
