/*eslint-disable*/
import React, { useEffect, useState} from "react";
import Button from "../CustomButtons/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
} from "@material-ui/core"
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import {
  Facebook,
  Twitter,
  Instagram,
  Edit,
  Streetview,
  AppsOutlined,
  Person,
  Help
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { openCustomDialog } from "../../../config/reduxstore/actions/ui/openCustomDialog"
import { setActiveComponent } from "../../../config/reduxstore/actions/ui/setActiveComponent"
import { setAlert } from "../../../config/reduxstore/actions/ui/setAlert"
import { tokenExpired } from '../../../config/reduxstore/actions/user/tokenExpired'
import { clearLocalStorage } from "../../../config/utilities/localStorage"
import getSubscription from "../../../config/requests/subscriptions/getSubscription";
import getSubscriptionLevels from "../../../config/requests/subscriptionLevels/getSubscriptionLevels";
import getApplications from "../../../config/requests/applications/getApplications";

import styles from "../../../assets/headerLinksStyle";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentUser = useSelector((state) => state.user.currentUser);
  const token = useSelector((state) => state.user.token);
  const name = useSelector((state) => state.user.currentUser.name);
  const stripe_id = useSelector((state) => state.user.currentUser.stripe_id);
  const user_subscription_id = useSelector((state) => state.user.currentUser.subscription_id);
  const subscriptionStatus = useSelector((state) => state.subscriptions.subscription.status)
  const applicationId = useSelector((state) => state.user.currentUser.application_id)
  const subscription_id = useSelector((state) => state.subscriptions.subscription.id)

  const handleLogout = (timerLogout) => {
    setIsLoading(true)
    clearLocalStorage()
    dispatch(tokenExpired())
    dispatch({ type: "DESTROY_AVAILABLE_SESSION" });
    dispatch(tokenExpired())
    if(timerLogout){
      // dispatch(
      //   setActiveComponent(`Main`)
      // )
      // dispatch(
      //   setActiveAdminComponent(``)
      // )
      dispatch(setAlert('warning', `You were logged out as your session has expired, please login!`))
    }else{
      dispatch(setAlert('success', `Logged out successfully!`))
    }
    setIsLoading(false)
    // dispatch(
    //   setActiveComponent(`Main`)
    // )
    // dispatch(
    //   setActiveAdminComponent(``)
    // )
  };
  
  useEffect(() => {
    // getUser(dispatch, setIsLoading, currentUser.id)
    // get subscription levels and pass stripe_id and price to stripe component
    if (token){
      if (user_subscription_id){
        getSubscription(dispatch, setIsLoading, user_subscription_id)
      }else{
        if (subscription_id){
          getSubscription(dispatch, setIsLoading, subscription_id)
        }
      }
      if(applicationId){
        getSubscriptionLevels(dispatch, setIsLoading, applicationId)
      }
    }
    getApplications(dispatch, setIsLoading)
  }, [dispatch, applicationId, user_subscription_id, subscription_id, token])

  useEffect(() => {
    if (currentUser && currentUser.last_login){
      const myInterval = setInterval(() => {
        const lastLogin = new Date(currentUser.last_login)
        const dateNow = new Date(Date.now())
        const lastLoginPlus1Day = lastLogin.setDate(lastLogin.getDate() + 1);
        const lastLoginPlus1DayFormatted = new Date(lastLoginPlus1Day)
        // test ("dateNow: ", dateNow)
        // test ("lastLoginPlus1DayFormatted: ", lastLoginPlus1DayFormatted)
        if(dateNow >= lastLoginPlus1DayFormatted){
          // test true
          handleLogout(true)
        }else{
          // test false
        }
      }, 10000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [currentUser])

  const handleOpenDialog = (dialogName) => {
    if(stripe_id && (user_subscription_id || subscription_id)){
      if(user_subscription_id){
        dispatch(
          openCustomDialog(`dialogs/${dialogName}`, {stripe_id: stripe_id, subscription_id: user_subscription_id})
        )
      }else{
        if(subscription_id){
          dispatch(
            openCustomDialog(`dialogs/${dialogName}`, {stripe_id: stripe_id, subscription_id: subscription_id})
          )
        }
      }
    }else{
      dispatch(
        openCustomDialog(`dialogs/${dialogName}`)
      )
    }
  };

  const handleContact = () => {
    dispatch(
      openCustomDialog("dialogs/Contact")
    )
  };

  const handleSetActiveComponent = (componentName) => {
    dispatch(
      setActiveComponent(`Views/${componentName}`)
    )
    props.handleDrawerToggle()
  };

  const headerLinksList = () => {
    if(name && !subscriptionStatus){
      return [
        <Typography onClick={() => handleLogout(false)} className={classes.dropdownLink}>
        Logout
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserSubscribe`)} className={classes.dropdownLink}>
        Subscribe
      </Typography>,
      ]
    }
    if(!name) {
      return [
        <Typography onClick={() => handleOpenDialog('UserLogin')} className={classes.dropdownLink}>
        Login
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserRegister`)} className={classes.dropdownLink}>
        Create Account
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserSubscribe`)} className={classes.dropdownLink}>
        Subscribe
      </Typography>,
      ]
    }
    if(name && subscriptionStatus) {
      return [
      <Typography onClick={() => handleLogout(false)} className={classes.dropdownLink}>
        Logout
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserProfile`)} className={classes.dropdownLink}>
        Profile
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserUpdateSubscription`)} className={classes.dropdownLink}>
        Update Subscription
      </Typography>,
      <Typography onClick={() => handleOpenDialog(`UserCancelSubscription`)} className={classes.dropdownLink}>
        Cancel Subscription
      </Typography>,
      ]
    }
  }

  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Contact"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={Edit}
          dropdownList={[
            <Typography onClick={handleContact} className={classes.dropdownLink}>
              Contact Us
            </Typography>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Explore"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={Streetview}
          dropdownList={[
            <Typography onClick={() => handleSetActiveComponent(`CurrentCustomers`)} className={classes.dropdownLink}>
              Current Customers
            </Typography>,
            <Typography onClick={() => handleSetActiveComponent(`AmazonsAlexa`)} className={classes.dropdownLink}>
              Amazon's Alexa
            </Typography>,
            <Typography onClick={() => handleSetActiveComponent(`Technologies`)} className={classes.dropdownLink}>
            Technologies
            </Typography>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Software"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={AppsOutlined}
          dropdownList={[
            <Typography onClick={() => handleSetActiveComponent(`IMS`)} className={classes.dropdownLink}>
              Inventory Management System
            </Typography>,
            <Typography onClick={() => handleSetActiveComponent(`EBS`)} className={classes.dropdownLink}>
              Event Booking Services
            </Typography>,
            <Typography onClick={() => handleSetActiveComponent(`STS`)} className={classes.dropdownLink}>
              Sports Training Sessions
            </Typography>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Help"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={Help}
          dropdownList={[
            <Typography onClick={() => handleSetActiveComponent(`StripeHelp`)} className={classes.dropdownLink}>
              Stripe
            </Typography>,
          ]}
        />
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Merchandise"
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={LocalOffer}
          dropdownList={[
            <Link to="/merchandise-shirts" className={classes.dropdownLink}>
              Shirts
            </Link>,
            <Link to="/merchandise-hats" className={classes.dropdownLink}>
              Hats
            </Link>
          ]}
        />
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://twitter.com/vixalopex"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <Twitter className={classes.socialIcons} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com/vixalopex/"
            target="_blank"
            className={classes.navLink}
          >
            <Facebook className={classes.socialIcons} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com/vixalopex/"
            target="_blank"
            className={classes.navLink}
          >
            <Instagram className={classes.socialIcons} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText={name ? name : "Login"}
          buttonProps={{
            className: classes.navLink,
          }}
          buttonIcon={Person}
          dropdownList={headerLinksList()}
        />
      </ListItem>
    </List>
  );
}
