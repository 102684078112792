//ui
export const DESTROY_AVAILABLE_SESSION = "DESTROY_AVAILABLE_SESSION";
export const UI_SET_ALERT = "UI_SET_ALERT";
export const UI_SET_CONTENT = "UI_SET_CONTENT";
export const UI_OPEN_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_CLOSE_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_SET_ACTIVE_COMPONENT = "UI_SET_ACTIVE_COMPONENT";

//email
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';

//user
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_TOKEN_EXPIRED = "USER_TOKEN_EXPIRED";
export const CURRENT_USER = "CURRENT_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const SUBSCRIBED_USER = "SUBSCRIBED_USER";
export const SET_ADMIN = 'SET_ADMIN'

//applications
export const APPLICATIONS_SUCCESS = 'APPLICATIONS_SUCCESS';

//subscriptions
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS_SUCCESS';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_REPORT = "SUBSCRIPTIONS_REPORT"

//subscription levels
export const SUBSCRIPTION_LEVEL = 'SUBSCRIPTION_LEVEL';
export const CREATE_SUBSCRIPTION_LEVEL_SUCCESS = 'CREATE_SUBSCRIPTION_LEVEL_SUCCESS';
export const SUBSCRIPTION_LEVELS_SUCCESS = 'SUBSCRIPTION_LEVELS_SUCCESS';
export const UPDATE_SUBSCRIPTION_LEVEL_SUCCESS = 'UPDATE_SUBSCRIPTION_LEVEL_SUCCESS';
export const DELETE_SUBSCRIPTION_LEVEL_SUCCESS = 'DELETE_SUBSCRIPTION_LEVEL_SUCCESS';
export const SUBSCRIBER_SUBSCRIPTION = 'SUBSCRIBER_SUBSCRIPTION';
export const SUBSCRIBER_SUBSCRIPTION_LEVEL = 'SUBSCRIBER_SUBSCRIPTION_LEVEL';

//stripe
export const GET_STRIPE_SECRET_KEY_SUCCESS = 'GET_STRIPE_SECRET_KEY_SUCCESS'
export const GET_STRIPE_PROMISE_KEY_SUCCESS = 'GET_STRIPE_PROMISE_KEY_SUCCESS'
export const GET_STRIPE_CUSTOMER_SECRET_KEY_SUCCESS = 'GET_STRIPE_CUSTOMER_SECRET_KEY_SUCCESS'
export const CREATE_STRIPE_CUSTOMER_SUCCESS = "CREATE_STRIPE_CUSTOMER_SUCCESS";
export const CREATE_STRIPE_SUBSCRIPTION_SUCCESS = "CREATE_STRIPE_SUBSCRIPTION_SUCCESS";
export const UPDATE_STRIPE_SUBSCRIPTION_SUCCESS = "UPDATE_STRIPE_SUBSCRIPTION_SUCCESS";
export const CANCEL_STRIPE_SUBSCRIPTION_SUCCESS = "CANCEL_STRIPE_SUBSCRIPTION_SUCCESS";