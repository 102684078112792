/* eslint-disable */
// user action constants
import {
    SUBSCRIPTION_LEVEL,
    SUBSCRIPTION_LEVELS_SUCCESS,
    CREATE_SUBSCRIPTION_LEVEL_SUCCESS,
    UPDATE_SUBSCRIPTION_LEVEL_SUCCESS,
    DELETE_SUBSCRIPTION_LEVEL_SUCCESS,
    SUBSCRIBER_SUBSCRIPTION_LEVEL
  } from "../actions/types";
  
  // default subscritions state
  const defaultState = {
    subscriptionLevel: {
        id: null,
        stripe_id: null,
        stripe_price_id: null,
        price: null,
        amount_of_coverage: null,
        application_id: null,
    },
    newSubscription: {},
    subscriberSubscriptionLevel: {},
    subscriptionLevels: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case SUBSCRIPTION_LEVEL:
        return {
          ...state,
          subscriptionLevel: action.payload
        }

      case SUBSCRIPTION_LEVELS_SUCCESS:
        return {
          ...state,
          subscriptionLevels: action.payload
        }

      case CREATE_SUBSCRIPTION_LEVEL_SUCCESS:
        const existingArray = state.subscriptionLevels;
        existingArray.push(action.payload);
        return {
          ...state,
          newSubscription: action.payload,
          subscriptionLevels: existingArray
        }

      case UPDATE_SUBSCRIPTION_LEVEL_SUCCESS:
        const filteredArray = state.subscriptionLevels.filter(subscriptionLevel => subscriptionLevel.id !== parseInt(action.payload.id))
        filteredArray.push(action.payload);
        return {
          ...state,
          subscriptionLevels: filteredArray,
        }

      case DELETE_SUBSCRIPTION_LEVEL_SUCCESS:
        const filteredData = state.subscriptionLevels.filter(subscriptionLevel => subscriptionLevel.id !== action.payload)
        return {
          ...state,
          subscriptionLevels: filteredData
        }

      case SUBSCRIBER_SUBSCRIPTION_LEVEL:
        return {
          ...state,
          subscriberSubscriptionLevel: action.payload
        }
    
      default:
        return state;
    }
  };
  